<template>
  <v-app id="inspire">
    <v-navigation-drawer
      v-model="drawer"
      :clipped="$vuetify.breakpoint.lgAndUp"
      app
    >
      <v-list dense>
        <template v-for="item in items">
          <v-row v-if="item.heading" :key="item.heading" align="center">
            <v-col cols="6">
              <v-subheader v-if="item.heading">
                {{ item.heading }}
              </v-subheader>
            </v-col>
            <v-col cols="6" class="text-center">
              <a href="#!" class="body-2 black--text">EDIT</a>
            </v-col>
          </v-row>
          <v-list-group
            v-else-if="item.children"
            :key="item.text"
            v-model="item.model"
            :prepend-icon="item.model ? item.icon : item['icon-alt']"
            append-icon=""
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>
                  {{ item.text }}
                </v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item v-for="(child, i) in item.children" :key="i" link>
              <v-list-item-action v-if="child.icon">
                <v-icon>{{ child.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  {{ child.text }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
          <router-link
            v-show="checkRoles(item.roles)"
            tag="div"
            :key="item.text"
            v-else
            :to="item.href"
          >
            <v-list-item link>
              <v-list-item-action>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  {{ item.text }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </router-link>
        </template>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar
      :clipped-left="$vuetify.breakpoint.lgAndUp"
      app
      color="blue darken-3"
      dark
    >
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title style="width: 300px" class="ml-0 pl-4">
        <span class="hidden-sm-and-down">Система управления</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn @click="logout" icon>
        <v-icon>mdi-exit-to-app</v-icon>
      </v-btn>
      <v-btn icon large>
        <v-avatar color="blue">
          <span class="white--text headline">{{
            user.nick && user.nick.slice(0, 2)
          }}</span>
        </v-avatar>
      </v-btn>
    </v-app-bar>
    <v-main class="layout">
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "DashboardLayout",
  props: {
    source: String
  },
  computed: {
    ...mapGetters("user", ["user"])
  },
  data: () => ({
    drawer: null,
    items: [
      {
        icon: "mdi-chart-areaspline",
        text: "Статистика",
        href: "/overview",
        roles: ["analitic", "general"]
      },
      {
        icon: "mdi-account-multiple",
        text: "Пользователи",
        href: "/users",
        roles: ["analitic", "general", "support", "majorSupport"]
      },
      // { icon: "mdi-account-multiple", text: "Фрод контроль", href: "/frod" },
      {
        icon: "mdi-cash-register",
        text: "Вывод средств",
        href: "/withdrawal",
        roles: ["analitic", "general", "majorSupport"]
      },
      {
        icon: "mdi-human-greeting",
        text: "Промокоды",
        href: "/bonus",
        roles: ["analitic", "general", "support", "majorSupport"]
      },
      {
        icon: "mdi-account-multiple-plus",
        text: "Статистика по рефералам",
        href: "/refferals",
        roles: ["analitic", "general"]
      },
      {
        icon: "mdi-network-pos",
        text: "Финансовая отчетность",
        href: "/invoices",
        roles: ["analitic", "general"]
      }
      // { icon: "mdi-gamepad-variant", text: "Настройка игр", href: "/games" }
    ]
  }),
  methods: {
    logout() {
      this.$store.dispatch("user/logout").then(
        () => {
          this.$router.push("/");
        },
        error => {
          this.$noty.error(
            `Ошибка авторизации: ${error.response.data.payload.msg}`
          );
        }
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.layout {
  background: #f7f7f8;
}
</style>
